<template>
  <div class="serial-code-list-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="序號" prop="code" align="center" />
      <BaseElTableColumn label="來源" prop="origin" align="center" />
      <BaseElTableColumn label="建立時間" prop="createdAt" align="center" />
      <BaseElTableColumn label="變更時間" prop="updatedAt" align="center" />
      <BaseElTableColumn
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      :loading="deleteLoading"
      @close="modal.delete = false"
      @delete="onDelete"
    />

    <SerialCodeInputModal
      v-if="modal.edit"
      mode="edit"
      :code="selectRow.code"
      :handleSubmit="handleCodeEdit"
      @refresh="$emit('refresh')"
      @close="modal.edit = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import SerialCodeInputModal from '@/components/SerialCode/SerialCodeInputModal.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { DeleteSerialActivityCode, UpdateSerialActivityCode } from '@/api/serialActivity'
import { serialActivityCodeOriginConfig } from '@/config/serialActivity'
import { formatDate } from '@/utils/date'
import { get, map } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SerialCodeListTable',
  components: { EmptyBlock, TableEditBtnGroup, DeleteDialog, SerialCodeInputModal },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const displayData = computed(() => {
      return map(props.tableData, i => {
        return {
          id: i.id,
          code: i.code,
          createdAt: formatDate(i.createdAt),
          updatedAt: formatDate(i.updatedAt),
          origin: get(serialActivityCodeOriginConfig, `${i.createMethod}.label`),
        }
      })
    })

    const selectRow = ref(null)
    const deleteLoading = ref(false)

    const modal = reactive({
      delete: false,
      edit: false,
    })

    const onRowEdit = (row) => {
      selectRow.value = row
      modal.edit = true
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const onDelete = async () => {
      deleteLoading.value = true
      const [, err] = await DeleteSerialActivityCode({
        shopId: shopId.value,
        id: selectRow.value.id,
      })
      deleteLoading.value = false
      if (err) {
        window.$message.console.error(err)
        return
      }
      modal.delete = false
      emit('refresh')
    }
    const handleCodeEdit = async (data, { emit, loading }) => {
      const [, err] = await UpdateSerialActivityCode({
        shopId: shopId.value,
        id: selectRow.value.id,
        code: data.code,
      })
      if (err) {
        loading.value = false
        window.$message.error(err)
        return
      }
      emit('refresh')
      emit('close')
    }
    return { modal, displayData, onRowEdit, onRowDelete, onDelete, handleCodeEdit, deleteLoading, selectRow }
  },
})
</script>

<style lang="postcss" scoped>

</style>
