<template>
  <div class="card-container form-wrapper">
    <div style="margin-bottom: 20px;">
      <p class="text-primary-100 font-bold text-lg">對應序號組合 </p>
    </div>
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top" @submit.prevent.native>
      <BaseElFormItem label="序號生成方式">
        <div style="margin-top: 16px">
          <BaseElButton plain @click="modal.batchCreate = true">大量匯入</BaseElButton>
          <BaseElButton plain @click="modal.systemCreate = true">系統生成</BaseElButton>
          <BaseElButton plain @click="modal.singleCreate = true">單次新增</BaseElButton>
        </div>
      </BaseElFormItem>
      <BaseElFormItem v-if="showTable" label="序號列表">
        <div style="margin-top: 16px">
          <BaseElInput
            v-model="search.code"
            style="width: 340px"
            placeholder="請輸入序號搜尋"
            clearable
            @clear="refresh(true)"
            @keypress.enter.native="refresh(true)"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search"
              @click="refresh(true)"
            />
          </BaseElInput>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <div class="flex items-center justify-center cursor-pointer text-gray-60" style="margin-bottom: 18px" @click="showTable = !showTable">
      <span>{{ showTable ? '收合序號' : '展開序號' }}</span>
      <span class="material-icons">{{ showTable ? 'expand_less' : 'expand_more' }} </span>
    </div>

    <div v-if="showTable">
      <SerialCodeListTable :tableData="tableData" @refresh="refresh" />
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </div>

    <SerialCodeInputModal
      v-if="modal.singleCreate"
      :handleSubmit="handleSingleCreate"
      @refresh="refresh"
      @close="modal.singleCreate = false"
    />
    <SerialCodeSystemGernateModal
      v-if="modal.systemCreate"
      :handleSubmit="handleSystemCreate"
      @refresh="refresh"
      @close="modal.systemCreate = false"
    />
    <SerialcodeImportModal
      v-if="modal.batchCreate"
      :handleSubmit="handleBatchCreate"
      :examplaeFile="serialCodeImportExample"
      @refresh="refresh"
      @close="modal.batchCreate = false"
      @success="handleBatchCreateSuccess"
      @error="handleBatchCreateError"
    />

    <BaseDialog v-if="modal.importSuccess" title="匯入成功" @close="modal.importSuccess = false" @confirm="modal.importSuccess = false">
      <div style="margin-bottom: 30px">
        檔案匯入成功，請查看序號
      </div>
    </BaseDialog>
    <BaseDialog v-if="modal.importError" title="匯入失敗" @close="modal.importError = false" @confirm="modal.importError = false">
      <div style="margin-bottom: 30px">
        <p style="margin-bottom: 30px">檔案匯入失敗，請下載檔案進行調整後再次匯入</p>
        <p class="text-primary-100 cursor-pointer underline" @click="downloadErrorFile">失敗說明檔案</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, nextTick, watch, computed, ref, reactive } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import {
  CreateSerialActivityCode, SystemCreateSerialActivityCode, BatchCreateSerialActivityCode,
  GetSerialActivityCode, GetSerialActivityCodeCount,
} from '@/api/serialActivity'
import SerialCodeInputModal from '@/components/SerialCode/SerialCodeInputModal.vue'
import SerialCodeSystemGernateModal from '@/components/SerialCode/SerialCodeSystemGernateModal.vue'
import SerialcodeImportModal from '@/components/SerialCode/SerialcodeImportModal.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import SerialCodeListTable from './SerialCodeListTable.vue'
import FormsContext from '../formsContext'
import { useTable } from '@/use/table'
import { useRoute } from 'vue-router/composables'
import { serialRewardTypeConfig, serialCodeImportExample, batchImportErrorCodeConfig } from '@/config/serialActivity'
import { get, map } from 'lodash'
import { ExportExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'SerialCodeFormBlock',
  components: {
    SerialCodeInputModal,
    SerialCodeListTable,
    SerialCodeSystemGernateModal,
    SerialcodeImportModal,
    BaseDialog,
  },
  props: {
    rewardId: String,
    rewardData: { type: Object, default: () => ({}) },
  },
  emits: ['remove', 'edit'],
  setup (props, { emit }) {
    const route = useRoute()
    const activityId = computed(() => route.params.id)
    const {
      shopId,
      fetchData, fetchDataCount, pageStartIndex, tableOptions,
      tableData, tableDataCount, search, extendData,
    } = useTable()
    const { initFormData, formRef, formData } = useBaseForm()
    initFormData({
      type: null,
      amount: null,
    })
    extendData('search', {
      code: null,
    })
    const showTable = ref(false)
    const errorFileData = ref(null)
    const modal = reactive({
      singleCreate: false,
      batchCreate: false,
      systemCreate: false,
      importSuccess: false,
      importError: false,
    })

    const formRules = computed(() => {
      const rules = {
        type: [noEmptyRules()],
        amount: [noEmptyRules()],
      }

      // handle rules here

      return rules
    })

    const compactData = computed(() => {
      const data = { ...formData }
      // data handle here
      return data
    })

    // 單次新增
    const handleSingleCreate = async (data, { emit, loading }) => {
      const [, err] = await CreateSerialActivityCode({
        shopId: shopId.value,
        serialActivityId: activityId.value,
        serialActivityRewardId: props.rewardId,
        code: data.code,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      emit('refresh')
      emit('close')
    }
    // 系統生成
    const handleSystemCreate = async (data, { emit, loading }) => {
      const [, err] = await SystemCreateSerialActivityCode({
        shopId: shopId.value,
        serialActivityId: activityId.value,
        serialActivityRewardId: props.rewardId,
        count: data.count,
        length: data.length,
        useUpperCase: data.useUpperCase,
        useLowerCase: data.useLowerCase,
        useNumber: data.useNumber,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      emit('refresh')
      emit('close')
    }
    const handleBatchCreate = async (codes) => {
      const [res, err] = await BatchCreateSerialActivityCode({
        shopId: shopId.value,
        serialActivityId: activityId.value,
        serialActivityRewardId: props.rewardId,
        codes,
      })
      console.log('err', err)
      if (err) throw err
      return res
    }

    const syncData = () => {
      const data = props.rewardData
      formData.type = data.rewardType
      formData.amount = data.rewardAmount
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        SerialActivityId: activityId.value,
        SerialActivityRewardId: props.rewardId,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
      }
      await Promise.allSettled([
        fetchData(GetSerialActivityCode, payload),
        fetchDataCount(GetSerialActivityCodeCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      await nextTick()
      FormsContext.setFormRef(props.rewardId, formRef.vue)
      if (props.rewardData) syncData()
      await getTableData()
      console.log('tableData.value', tableData.value)
      FormsContext.setRewardSet(props.rewardId, {
        codes: map(tableData.value, i => ({
          code: i.code,
          origin: i.createMethod,
          createdAt: formatDate(i.createdAt),
          updatedAt: formatDate(i.updatedAt),
        })),
      })
    }

    const downloadErrorFile = async () => {
      ExportExcel(errorFileData.value, '匯入失敗說明檔', '匯入失敗說明檔')
    }

    const handleBatchCreateSuccess = () => {
      modal.importSuccess = true
      refresh()
    }
    const handleBatchCreateError = (errorData) => {
      const { errors, codes } = errorData
      const data = []
      codes.forEach((code, index) => {
        const errorCode = get(errors, `[${index}].error`)
        const errorMsg = get(batchImportErrorCodeConfig, `${errorCode}.label`)
        data.push({
          序號: code,
          錯誤: errorMsg,
        })
      })
      errorFileData.value = data
      modal.importError = true
    }

    watch(compactData, () => {
      FormsContext.setFormData(props.formId, compactData.value)
    })

    onMounted(async () => {
      refresh()
    })

    return {
      formRef,
      get,
      serialRewardTypeConfig,
      formData,
      formRules,
      showTable,
      modal,
      handleSingleCreate,
      handleSystemCreate,
      handleBatchCreate,
      refresh,
      tableData,
      tableOptions,
      tableDataCount,
      serialCodeImportExample,
      handleBatchCreateSuccess,
      handleBatchCreateError,
      downloadErrorFile,
      search,
    }
  },
})
</script>

<style lang="postcss" scoped>
.form-wrapper {
  @apply px-[24px] py-[20px];
}
</style>
